/* You can add global styles to this file, and also import other style files */
@use "app/@core/styles/core.styles";
@import 'swiper/swiper-bundle';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

#nector-reward-page-container {
    position: relative;
    z-index: 1;
}
