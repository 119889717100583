@use '@angular/material' as mat;

@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50: #eefcf7,
    100: #ccf5e8,
    200: #a9efd9,
    300: #87e8c9,
    400: #65e2ba,
    500: #20a87e,
    600: #0d4533,
    700: #0a3326,
    800: #072219,
    900: #03110d,
    A100: #31d8a3,
    A200: #27ce99,
    A400: #24bc8c,
    A700: #1d9a73,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-secondary: (
    50: #f9e0dc,
    100: #f7d1ca,
    200: #f4c1b8,
    300: #f1b2a7,
    400: #eea395,
    500: #eb8f7f,
    600: #e98472,
    700: #e67460,
    800: #e3654f,
    900: #de462b,
    A100: #ffffff,
    A200: #fffaee,
    A400: #ffe9bb,
    A700: #ffe1a1,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
// $md-accent: (
//   50 : #FFFB5D,
//   100 : #FFFB5D,
//   200 : #FFFB5D,
//   300 : #FEF657,
//   400 : #FEF051,
//   500 : #FDEB4B,
//   600 : #FDE646,
//   700 : #FCE040,
//   800 : #FBDB3A,
//   900 : #FAD02E,
//   A100 : #FFFB5D,
//   A200 : #FFFB5D,
//   A400 : #FAD02E,
//   A700 : #E1B628,
//   contrast: (
//     50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #000000,
//     600 : #000000,
//     700 : #000000,
//     800 : #000000,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   ),
//   backgound: #000000
// );

$marche-primary: mat.define-palette($md-primary);
$marche-secondary: mat.define-palette($md-secondary);

$marche-theme: mat.define-light-theme($marche-primary, $marche-secondary);

@include mat.all-component-themes($marche-theme);

$marche-typography: mat.define-typography-config(
    $font-family: 'Roboto Regular, sans-serif',
);

@include mat.all-component-typographies($marche-typography);
