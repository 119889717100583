$primary: #20a87e;
$primary_variant: #007b4f;
$primary_disabled: #cfebe1;
$color_on_primary: #ffffff;
$secondary: #eb8f7f;
$background: #ffffff;
$surface: #ffffff;
$colorOnBackground: #000;
$colorOnSurface: #000;
$error: #ff0000;
